<template>
    <div class="home-view" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
        <div class="container" :class="{ 'dark-mode': isDarkMode }">
            <div class="typing-container">
                <h1>
                    Hello&nbsp;<span typing-speed="100" typing-delay="1000" words="world.,infinite loop enthusiast,person?,brave soul who clicked on this!,my favorite virtual stalker.,... you really just sat through all of them 👏."></span>
                </h1>
            </div>
            <p style="font-size: 20px;">I'm Sandor, currently a BsC student at University of Tartu, studying Computer Science.</p>
            <p style="font-size: 20px;">This is a test build.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    props: {
        isDarkMode: {
            type: Boolean,
            required: true
        }
    },
    emits: ['toggle-dark-mode'],
    mounted() {
        var wordsToType = document.querySelector("span[words]").getAttribute("words").split(','), 
            typer =  document.querySelector("span[words]"), 
            typingSpeed = (parseInt(typer.getAttribute('typing-speed')) || 70), 
            typingDelay = (parseInt(typer.getAttribute('typing-delay')) || 700);
        
        var currentWordIndex = 0, currentCharacterIndex = 0; 

        function type() {
            var wordToType = wordsToType[currentWordIndex % wordsToType.length];

            if (currentCharacterIndex < wordToType.length) {
                typer.innerHTML += wordToType[currentCharacterIndex++];
                setTimeout(type, typingSpeed);
            } else {
                setTimeout(erase, typingDelay);
            }
        }

        function erase() {
            var wordToType = wordsToType[currentWordIndex % wordsToType.length]; 
            if (currentCharacterIndex > 0) {
                typer.innerHTML = wordToType.substr(0, --currentCharacterIndex - 1);
                setTimeout(erase, typingSpeed);
            } else {
                currentWordIndex++; 
                setTimeout(type, typingDelay);
            }
        }

        type(); 
    },
    methods: {
        handleTouchStart(event) {
            this.startY = event.touches[0].clientY;
        },
        handleTouchMove(event) {
            const touchY = event.touches[0].clientY;
            const deltaY = touchY - this.startY;

            const scrollTop = this.$el.scrollTop;
            const scrollHeight = this.$el.scrollHeight;
            const offsetHeight = this.$el.offsetHeight;

            // Prevent overscrolling
            if ((scrollTop === 0 && deltaY > 0) || (scrollTop + offsetHeight >= scrollHeight && deltaY < 0)) {
                event.preventDefault();
            }
        }
    }
}
</script>

<style scoped>
body {
    padding: 0; 
    margin: 0; 
    background-color: black; 
    color: white; 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Use Apple font stack */
}
.container {
    height: 99vh; 
    width: 99vw;  
    display: flex; 
    flex-direction: column; /* Ensure vertical stacking */
    justify-content: center; 
    align-items: center;
    font-size: 1.52em; 
    text-align: center;
}
h1 {
    font-weight: 200;
}
.container span {
    color: green; /* Text color for typing animation */
}
.container span::after {
    content: '|';
    animation-name: blinking; 
    animation-duration: .71s; 
    animation-iteration-count: infinite;
}
.container.dark-mode span::after {
    color: lightgrey; /* Blinking cursor color in dark mode */
}
.container:not(.dark-mode) span::after {
    color: black; /* Blinking cursor color in light mode */
}
@keyframes blinking {
    0% {
        opacity: 1; /* Fully visible */
    }
    50% {
        opacity: 0; /* Invisible */
    }
    100% {
        opacity: 1; /* Fully visible */
    }
}
.home-view {
    height: 100vh; /* Ensure it takes full height */
    overflow-y: auto; /* Allow vertical scrolling */
}
</style>
<template>
<div id="app" :class="{ 'dark-mode': isDarkMode }">
    <MovingDotsBackground :isDarkMode="isDarkMode" /> <!-- Ensure this line is present -->
    <AnimatedLogo :isDarkMode="isDarkMode" />
    <div class="dark-mode-toggle">
      <input type="checkbox" id="darkmode" :checked="isDarkMode" @change="toggleDarkMode">
      <label for="darkmode"></label>
    </div>
    <BackgroundFlares />
    <div class="frosted-glass" :class="{ 'dark-mode': isDarkMode }">
      <main class="main-content">
        <MainContent @section-change="updateCurrentSection" :isDarkMode="isDarkMode" ref="mainContent" />
      </main>
      <SideNavigation :currentSectionIndex="currentSectionIndex" @nav-click="handleNavClick" />
    </div>
    <!-- Remove the following line -->
    <!-- <button @click="toggleDarkMode">Toggle Dark Mode</button> -->
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import SideNavigation from './components/SideNavigation.vue'
import MainContent from './components/MainContent.vue'
import BackgroundFlares from './components/BackgroundFlares.vue'
import AnimatedLogo from './components/AnimatedLogo.vue'

export default {
  name: 'App',
  components: {
    SideNavigation,
    MainContent,
    BackgroundFlares,
    AnimatedLogo,
  },
  setup() {
    const currentSectionIndex = ref(0);
    const mainContent = ref(null);
    const isDarkMode = ref(false); // dark by default

    const appStyle = computed(() => ({
      backgroundColor: isDarkMode.value ? '#0d0d0d' : '#e7e7e7',
    }));

    const updateCurrentSection = (index) => {
      currentSectionIndex.value = index;
    };

    const handleNavClick = (index) => {
      if (mainContent.value) {
        mainContent.value.scrollToSection(index);
      }
    };

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      console.log('App: Dark mode toggled:', isDarkMode.value);
      localStorage.setItem('darkMode', isDarkMode.value);

      // Dynamically update the body background color
      document.body.style.backgroundColor = isDarkMode.value ? '#0d0d0d' : '#e7e7e7';
    };

    onMounted(() => {
  const updateViewportHeight = () => {
    const visibleHeight = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${visibleHeight * 0.01}px`);
  };

  const detectBrowserAndSetClass = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Detect Chrome (not Edge, Opera, or Safari)
    if (userAgent.includes('chrome') && !userAgent.includes('edg') && !userAgent.includes('opr')) {
      document.body.classList.add('chrome');
    }
    // Detect Safari (not Chrome)
    else if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
      document.body.classList.add('safari');
    }
  };

  window.addEventListener('resize', updateViewportHeight);
  updateViewportHeight();
  detectBrowserAndSetClass();
});
    return {
      currentSectionIndex,
      updateCurrentSection,
      handleNavClick,
      mainContent,
      isDarkMode,
      toggleDarkMode,
      appStyle,
    };
  },
};
</script>

<style>
@import './styles/main.css';
@import './styles/variables.css';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  background-color: var(--background-color-dark); /* Dynamically set */
  overscroll-behavior: none;
  touch-action: manipulation;
}

body {
  padding-bottom: env(safe-area-inset-bottom); /* Safe area for mobile browsers */
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Use Apple font stack */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 30vh;
  position: relative;
  color: var(--text-color);
  transition: color 0.3s ease, background-color 0.3s ease;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform-style: preserve-3d;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
  transition: opacity 0.5s ease;
}

.frosted-glass {
  position: relative;
  min-height: 100vh;
  max-height: 10000vh;
  display: flex;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  will-change: backdrop-filter;
}

.frosted-glass:not(.dark-mode) {
  background-color: var(--frosted-bg);
}

.dark-mode .frosted-glass {
  background-color: transparent;
}

.main-content {
  height: calc(100vh - env(safe-area-inset-bottom, 60px)); /* Proper height for content */
  overflow-y: auto; /* Enable scrolling only within the main content */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS Safari */
  touch-action: pan-y;
}

.dark-mode-toggle {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 1000;
}

:root {
  --toggleSize: 50px;
  --toggle-color: black;
  --toggle-bg: rgb(231, 231, 231);
}

.dark-mode {
  --toggle-color: rgb(231, 231, 231);
  --toggle-bg: black;
}

input[type="checkbox"] {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--toggleSize);
  height: var(--toggleSize);
  position: relative;
  cursor: pointer;
}

label::before, label::after {
  content: '';
  display: block;
  position: absolute;
}

label::before {
  width: 70%;
  height: 70%;
  background-color: var(--toggle-color);
  clip-path: polygon(50% 0, 65% 15%, 85% 15%, 85% 35%, 100% 50%, 85% 65%, 85% 85%, 65% 85%, 50% 100%, 35% 85%, 15% 85%, 15% 65%, 0 50%, 15% 35%, 15% 15%, 35% 15%);
  transition: transform 0.5s ease-in-out, background-color 0.3s ease;
}

label::after {
  background-color: var(--toggle-bg);
  outline: 0px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='50%' cy='50%' r='50%' fill='%23000000' /></svg>");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  box-sizing: border-box;
  transition: background-position 0.3s ease-in-out, background-color 0.3s ease;
}

.dark-mode label::after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='50%' cy='50%' r='50%' fill='%23FFFFFF' /></svg>");
}

input[type="checkbox"]:checked + label::before {
  transform: rotate(270deg);
}

input[type="checkbox"]:checked + label::after {
  background-position: -30% 50%;
}

@media (max-width: 768px) {
  .dark-mode-toggle {
    top: 10px;
    right: 10px;
  }

  @media (max-width: 768px) {
  .main-content {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom)); /* Fallback for older browsers */
  }
}

  :root {
    --toggleSize: 40px;
  }

  label::before {
    width: 60%;
    height: 60%;
  }

  label::after {
    width: 40%;
    height: 40%;
  }
}

/* ... rest of your styles ... */
</style>

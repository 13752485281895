<template>
  <div class="timeline-wrapper">
    <h1>What did I do? <span></span></h1>
    <div class="timeline-container">
      <!-- The gradient timeline line -->
      <div class="timeline-line"></div>
      <!-- Timeline dots with diagonal labels -->
      <div
        v-for="(event, index) in timelineEvents"
        :key="event.id"
        class="timeline-dot"
        :class="{ active: activeIndex === index }"
        :style="{ left: event.left + '%' }"
        @mouseenter="setActive(index)"
        @click="setActive(index)"
      >
        <div class="dot"></div>
        <div class="dot-label">
          <span>{{ event.year }}</span><br />
          <span>{{ event.label }}</span>
        </div>
      </div>
    </div>
    <!-- Transition for the scrolling description -->
    <transition name="scroll" mode="out-in">
      <div class="description-container" v-if="activeEvent" :key="activeEvent.id">
        <p>
          <strong>{{ activeEvent.period }}</strong> – {{ activeEvent.description }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TimelineView",
  data() {
    const events = [
      {
        id: 1,
        period: "2016–2019",
        year: 2016,
        label: "NRG",
        description:
          "I attended NRG High School, majoring in Physics to build a strong analytical foundation."
      },
      {
        id: 2,
        period: "2019–2020",
        year: 2019,
        label: "Defense",
        description:
          "I served in the Estonian Defense Forces, developing discipline, teamwork, and leadership skills."
      },
      {
        id: 3,
        period: "2020–2021",
        year: 2020,
        label: "TalTech",
        description:
          "I studied Hardware Development & Programming at TalTech, deepening my technical expertise."
      },
      {
        id: 4,
        period: "May 2021–Sep 2022",
        year: 2021,
        label: "Foxway",
        description:
          "I advanced from Junior Tester to Senior Tester at Foxway, showcasing rapid career growth."
      },
      {
        id: 5,
        period: "Aug 2022–Jun 2025",
        year: 2022,
        label: "Tartu",
        description:
          "I'm pursuing a Computer Science BSc at the University of Tartu, focusing on software development and AI."
      }
    ];

    // Dynamically compute the minimum and maximum years from the events.
    // This ensures the first event appears at 0% and the last at 100%.
    const years = events.map(event => event.year);
    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);
    events.forEach(event => {
      event.left = ((event.year - minYear) / (maxYear - minYear)) * 100;
    });
    return {
      timelineEvents: events,
      activeIndex: 0
    };
  },
  computed: {
    activeEvent() {
      return this.timelineEvents[this.activeIndex];
    }
  },
  methods: {
    setActive(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style scoped>
/* --- Color Mode Variables --- */
:root {
  --bg-color-light: #ECF0F1;
  --text-color-light: #424949;
  --bg-color-dark: #121212;
  --text-color-dark: #ffffff;
}
@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: var(--bg-color-dark);
    --text-color: var(--text-color-dark);
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --bg-color: var(--bg-color-light);
    --text-color: var(--text-color-light);
  }
}

/* --- Reset --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* --- Main Wrapper --- */
.timeline-wrapper {
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}

/* --- Title --- */
.timeline-wrapper h1 {
  font-size: 2rem;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.timeline-wrapper h1 span {
  font-size: 1rem;
  color: var(--text-color);
}

/* --- Timeline Container --- */
.timeline-container {
  position: relative;
  width: 100%;
  max-width: 1400px;
  min-width: 800px;
  height: 80px;
  margin: 0 auto;
  cursor: pointer;
}

/* --- Gradient Timeline Line --- */
.timeline-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to right,
    #4285F4, #DB4437, #F4B400, #0F9D58, #4285F4
  );
  border-radius: 3px;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
}
.timeline-container:hover .timeline-line {
  transform: translateY(-50%) scaleY(1.5);
}

/* --- Timeline Dots --- */
.timeline-dot {
  position: absolute;
  top: 50%;
  /* The -50% X-translation centers the dot on its computed left value */
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.2s ease;
}
.timeline-dot .dot {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 3px solid currentColor;
  border-radius: 50%;
  transition: transform 0.5s ease;
}
.timeline-dot:hover .dot,
.timeline-dot.active .dot {
  transform: scale(1.5);
}

/* --- Diagonal Dot Label --- */
.dot-label {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg) scale(1);
  font-size: 0.8rem;
  color: currentColor;
  white-space: nowrap;
  opacity: 0.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.timeline-dot:hover .dot-label,
.timeline-dot.active .dot-label {
  opacity: 1;
  transform: translateX(-50%) rotate(-45deg) scale(1.2);
}

/* --- Description Container --- */
.description-container {
  position: relative;
  min-height: 150px;
  margin-top: 60px;
  width: 100%;
  max-width: 600px;
  overflow: visible;
}
.description-container p {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  white-space: normal;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* --- Scroll Transition for Description --- */
.scroll-enter-active {
  animation: scrollIn 0.6s ease-in-out forwards;
}
.scroll-leave-active {
  animation: scrollOut 0.6s ease-in-out forwards;
}
@keyframes scrollIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes scrollOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

/* --- Mobile Adjustments --- */
@media (max-width: 800px) {
  /* Use the full width of the viewport with minimal horizontal padding */
  .timeline-container {
    width: 100%;
    max-width: none;
    min-width: 0;
    height: 60px;
    padding: 0px 5px;
  }

  .timeline-wrapper h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .timeline-dot .dot {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }

  .dot-label {
    top: -35px;
    font-size: 0.7rem;
    padding-top: 60px;
  }

  .description-container {
    margin-top: 30px;
    max-width: 100%;
    font-size: 1rem;
    padding: 0 5px;
  }
}
</style>
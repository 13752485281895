<template>
  <div
    class="contact-view"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
  >
    <div class="contact-content">
      <!-- Left: The icons container (angled icons with labels) -->
      <div class="icons-wrapper">
        <ul>
          <!-- Email with rounded stacks -->
          <li class="email">
            <a href="mailto:sandor@vunk.pro" class="logo-link">
              <!-- Four animated background layers -->
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <!-- Icon image -->
              <img :src="emailLogo" alt="Email Logo" class="logo" />
            </a>
            <div class="label">Email</div>
          </li>
          <!-- LinkedIn with rounded stacks -->
          <li class="linkedin">
            <a href="https://www.linkedin.com/in/sandor-vunk/" class="logo-link">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <img :src="linkedinLogo" alt="LinkedIn Logo" class="logo" />
            </a>
            <div class="label">LinkedIn</div>
          </li>
          <!-- GitHub with circular stacks -->
          <li class="github">
            <a href="https://github.com/koodikirjutaja" class="logo-link">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <img :src="githubLogo" alt="GitHub Logo" class="logo" />
            </a>
            <div class="label">GitHub</div>
          </li>
        </ul>
      </div>
      <!-- Right: The frosted glass info box -->
      <div class="info-box">
        <p>
          Feel free to reach out to me for any inquiries or collaborations.
          I'm excited to connect and explore new opportunities together!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import emailLogo from '@/assets/Mail_(iOS).svg';
import linkedinLogo from '@/assets/LinkedIn_icon.svg';
import githubLogo from '@/assets/Octicons-mark-github.svg';

export default {
  data() {
    return {
      emailLogo,
      linkedinLogo,
      githubLogo,
      startY: 0,
    };
  },
  methods: {
    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const touchY = event.touches[0].clientY;
      const deltaY = touchY - this.startY;
      const scrollTop = this.$el.scrollTop;
      const scrollHeight = this.$el.scrollHeight;
      const offsetHeight = this.$el.offsetHeight;
      if (
        (scrollTop === 0 && deltaY > 0) ||
        (scrollTop + offsetHeight >= scrollHeight && deltaY < 0)
      ) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
}

/* Overall container */
.contact-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

/* Flex container for icons and info box */
.contact-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

/* ===============================
   ICONS WRAPPER (Left Side)
   =============================== */
.icons-wrapper ul {
  position: relative;
  display: flex;
  transform: rotate(-25deg) skew(25deg);
  margin: 0;
  padding: 0;
}

.icons-wrapper ul li {
  position: relative;
  list-style: none;
  margin: 0 10px;
  text-align: center;
}

.logo-link {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
}

.icons-wrapper ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: rgba(255, 255, 255, 0.2);
  font-size: 30px !important;
  transition: 0.2s;
}

/* Hover effect for all spans */
.icons-wrapper ul li:hover span {
  z-index: 1000;
  transition: 0.5s;
  color: var(--nav-text-color);
}

/* Staggered transforms and opacities on hover */
.icons-wrapper ul li:hover span:nth-child(1) {
  transform: translate(0px, 0px);
  opacity: 0.2;
}
.icons-wrapper ul li:hover span:nth-child(2) {
  transform: translate(10px, -10px);
  opacity: 0.4;
}
.icons-wrapper ul li:hover span:nth-child(3) {
  transform: translate(20px, -20px);
  opacity: 0.6;
}
.icons-wrapper ul li:hover span:nth-child(4) {
  transform: translate(30px, -30px);
  opacity: 0.8;
}

/* Restore background colors for the stacks on hover */
/* Email */
.icons-wrapper ul li.email:hover span {
  background: #3b5999;
}
/* LinkedIn */
.icons-wrapper ul li.linkedin:hover span {
  background: #55acee;
}
/* GitHub */
.icons-wrapper ul li.github:hover span {
  background: #555;
}

/* Rounded corners for email and LinkedIn stacks */
.email .logo-link span,
.linkedin .logo-link span {
  border-radius: 15px;
}

/* Circular stacks for GitHub */
.github .logo-link span {
  border-radius: 50%;
}

.logo {
  width: 100%;
  height: auto;
  position: relative;
  transform: translate(0, 0);
  opacity: 1;
  z-index: 2;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.icons-wrapper ul li:hover .logo {
  transform: translate(40px, -40px);
  opacity: 1;
  z-index: 1001;
}

.label {
  margin-top: 10px;
  font-size: 14px;
  color: var(--nav-text-color);
}

/* ===============================
   INFO BOX (Right Side)
   =============================== */
.info-box {
  background-color: var(--nav-bg-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.1);
  color: var(--nav-text-color);
  max-width: 300px;
  transition: all 0.3s ease;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .info-box {
    background-color: var(--nav-bg-color-transparent);
    -webkit-backdrop-filter: var(--frosted-backdrop, blur(10px));
    backdrop-filter: var(--frosted-backdrop, blur(10px));
  }
}
</style>
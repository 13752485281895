<template>
  <div>
    <!-- Desktop Navigation -->
    <nav class="side-navigation desktop-nav">
      <ul>
        <li v-for="(section, index) in sections" :key="section">
          <a
            @click.prevent="handleClick(index)"
            :href="`#${section}`"
            :class="{ active: currentSectionIndex === index }"
            :style="{ '--underline-color': getDotColor(section) }"
          >
            <span class="dot" :style="{ backgroundColor: getDotColor(section) }"></span>
            <span class="section-name">{{ section }}</span>
          </a>
        </li>
      </ul>
    </nav>

    <!-- Mobile Navigation -->
    <nav class="side-navigation mobile-nav">
      <ul>
        <li v-for="(section, index) in sections" :key="section">
          <a
            @click.prevent="handleClick(index)"
            :href="`#${section}`"
            :class="{ active: currentSectionIndex === index }"
            :style="{ '--underline-color': getDotColor(section) }"
          >
            <span class="dot" :style="{ backgroundColor: getDotColor(section) }"></span>
            <span class="section-name">{{ section }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SideNavigation",
  props: {
    currentSectionIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const sections = [
      "home",
      "about",
      "projects",
      "skills",
      "experience",
      "contact"
    ];

    const getDotColor = (section) => {
      const colors = {
        home: "#ff6b6b",
        about: "#4ecdc4",
        projects: "#45aaf2",
        skills: "#feca57",
        experience: "#ff9ff3",
        contact: "#FFFFFF"
      };
      return colors[section] || "#000000";
    };

    const handleClick = (index) => {
      emit("nav-click", index);
    };

    return {
      sections,
      getDotColor,
      handleClick
    };
  }
};
</script>

<style scoped>
/* Desktop Navigation Styles */
.desktop-nav {
  position: fixed;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--nav-bg-color);
  opacity: var(--nav-opacity);
  padding: 20px;
  box-shadow: -2px 0 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.3s ease;
  width: 100px;
  height: 210px;
  border-radius: 15px;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .desktop-nav {
    opacity: 1;
    background-color: var(--nav-bg-color-transparent);
    -webkit-backdrop-filter: var(--frosted-backdrop);
    backdrop-filter: var(--frosted-backdrop);
  }
}

.desktop-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.desktop-nav li {
  margin: 10px 0;
}

.desktop-nav a {
  display: flex;
  align-items: center;
  color: var(--nav-text-color);
  text-decoration: none;
  font-size: 14px;
  padding: 5px;
  border-radius: 15px;
  transition: all 0.3s ease;
  position: relative;
}

.desktop-nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--underline-color);
  transition: width 0.3s ease;
}

.desktop-nav a:hover::after,
.desktop-nav a.active::after {
  width: 100%;
}

.desktop-nav .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

/* Mobile Navigation Styles */
.mobile-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--nav-bg-color);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* Use safe-area inset to ensure proper spacing on devices with a notch */
  padding-bottom: calc(env(safe-area-inset-bottom)-10px);
}

.mobile-nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px 0; /* default padding for non-Safari browsers */
  margin: 0;
  list-style-type: none;
}


.mobile-nav li {
  flex: 1;
  text-align: center;
}

.mobile-nav a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--nav-text-color);
  text-decoration: none;
  font-size: 12px;
  padding: 5px;
  position: relative;
}

.mobile-nav .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.mobile-nav .section-name {
  font-size: 10px;
  text-transform: capitalize;
}

.mobile-nav a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: var(--underline-color);
  transition: all 0.3s ease;
  transform: translateX(-50%);
}

.mobile-nav a.active::after {
  width: 100%;
}

/* Responsive Behavior */
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}
</style>
<template>
  <canvas ref="canvas" :width="width" :height="height" :style="canvasStyle"></canvas>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'

export default {
  name: 'BackgroundDots',
  props: {
    isDarkMode: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const canvas = ref(null)
    const width = ref(window.innerWidth)
    const height = ref(window.innerHeight)
    const dots = ref([])
    let animationId = null

    const dotSize = 3 // Size of the dots
    const dotSpacing = 60 // Spacing between the dots
    const waveAmplitude = 10 // How much the dots move vertically and horizontally
    const waveFrequency = 0.4 // Frequency of the wave movement
    let time = 0 // Keep track of time for the animation

    // Create the dots in a grid pattern
    const createDots = () => {
    dots.value = []
    const cols = Math.ceil(width.value / dotSpacing) + 2
    const rows = Math.ceil(height.value / dotSpacing) + 2

    // Use CSS variables for dot colors
    const dotColor = getComputedStyle(document.documentElement).getPropertyValue('--dot-color').trim()

    for (let x = 0; x < cols; x++) {
      for (let y = 0; y < rows; y++) {
        dots.value.push({
          initialX: x * dotSpacing,
          initialY: y * dotSpacing,
          size: dotSize,
          color: dotColor // Dynamically apply dot color based on dark mode
        })
      }
    }
  }


    // Animate the dots in both horizontal and vertical sine wave pattern
    const drawDots = () => {
      if (!canvas.value) return

      const ctx = canvas.value.getContext('2d')
      ctx.clearRect(0, 0, width.value, height.value)

      dots.value.forEach(dot => {
        // Vertical wave
        const waveY = waveAmplitude * Math.sin(dot.initialX * waveFrequency + time)
        // Horizontal wave
        const waveX = waveAmplitude * Math.sin(dot.initialY * waveFrequency + time)

        ctx.beginPath()
        ctx.arc(dot.initialX + waveX, dot.initialY + waveY, dot.size / 2, 0, Math.PI * 2)
        ctx.fillStyle = dot.color
        ctx.fill()
      })
    }

    let lastTime = 0
    const fps = 60 // Limit to 60 frames per second
    const fpsInterval = 1000 / fps

    const animateWithFpsLimit = (currentTime) => {
      animationId = requestAnimationFrame(animateWithFpsLimit)

      const elapsed = currentTime - lastTime

      if (elapsed > fpsInterval) {
        lastTime = currentTime - (elapsed % fpsInterval)
        time += 0.012 // Speed of the wave animation
        drawDots()
      }
    }

    const handleResize = () => {
      width.value = window.innerWidth
      height.value = window.innerHeight
      if (canvas.value) {
        // Set the canvas dimensions to match the window size
        canvas.value.width = width.value
        canvas.value.height = height.value
      }
      createDots() // Recreate dots grid on resize
    }

    watch(() => props.isDarkMode, () => {
      // Update dots when dark mode changes
      createDots()
      drawDots() // Force redraw
    })


    onMounted(() => {
      handleResize() // Initialize canvas and dots on mount
      animationId = requestAnimationFrame(animateWithFpsLimit)
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      cancelAnimationFrame(animationId)
      window.removeEventListener('resize', handleResize)
    })

    const canvasStyle = computed(() => ({
      backgroundColor: `var(--bg-color)`
    }))

    return { canvas, width, height, canvasStyle }
  }
}
</script>

<style scoped>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  z-index: -1;
  pointer-events: none;
}
</style>
<template>
  <div class="about-view" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
    <div class="about-box">
      <h2>About Me</h2>

      <section v-for="section in sections" :key="section.name">
        <h3 @click="toggleSection(section.name)">
          <span class="section-title">{{ section.title }}</span>
          <span class="accordion-indicator">
            {{ isActive(section.name) ? '-' : '+' }}
          </span>
        </h3>

        <transition
          name="accordion"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div class="section-content" v-show="isActive(section.name) || !isMobile">
            <p v-for="(para, index) in section.content" :key="index">{{ para }}</p>
          </div>
        </transition>
      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  data() {
    return {
      activeSection: null,
      startY: 0,
      mobileBreakpoint: 768,
      sections: [
        {
          name: "professional",
          title: "Professional Experience",
          content: [
            "Started as a junior tester and became senior in a year, driven by adaptability and learning.",
            "Skilled in Python, Java, and C, with web development expertise in Vue.js, HTML, CSS, and JavaScript."
          ]
        },
        {
          name: "education",
          title: "Education & Technical Skills",
          content: [
            "Final-year CS student in University of Tartu, currently writing a thesis on Causal Information Extraction with LLMs.",
            "Strong in databases (MySQL, MongoDB, PostgreSQL) and hardware programming from TalTech studies."
          ]
        },
        {
          name: "personal",
          title: "Personal Interests",
          content: [
            "When I'm not immersed in coding or research, I enjoy playing disc golf and gaming.",
            "I'm a cool-minded grinder who continuously explores new technologies and stays updated with the latest industry innovations."
          ]
        }
      ]
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < this.mobileBreakpoint;
    }
  },
  methods: {
    toggleSection(sectionName) {
      if (this.isMobile) {
        this.activeSection = this.activeSection === sectionName ? null : sectionName;
      }
    },
    isActive(sectionName) {
      return this.isMobile ? this.activeSection === sectionName : true;
    },
    beforeEnter(el) {
      el.style.maxHeight = "0px";
      el.style.opacity = "0";
    },
    enter(el) {
      el.style.transition = "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out";
      el.style.maxHeight = el.scrollHeight + "px";
      el.style.opacity = "1";
    },
    leave(el) {
      el.style.transition = "max-height 0.4s ease-in-out, opacity 0.4s ease-in-out";
      el.style.maxHeight = "0px";
      el.style.opacity = "0";
    },
    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const touchY = event.touches[0].clientY;
      const deltaY = touchY - this.startY;
      const scrollTop = this.$el.scrollTop;
      const scrollHeight = this.$el.scrollHeight;
      const offsetHeight = this.$el.offsetHeight;
      if ((scrollTop === 0 && deltaY > 0) || (scrollTop + offsetHeight >= scrollHeight && deltaY < 0)) {
        event.preventDefault();
      }
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.$forceUpdate();
    });
  }
};
</script>

<style scoped>
/* --- Global Reset --- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* --- Main Wrapper --- */
.about-view {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

}

/* --- Content Box (Wider on Mobile) --- */
.about-box {

  color: var(--text-color); /* Use CSS variable for text color */
  border-radius: 15px;
  padding: 30px;
  max-width: 800px;
  width: 90%; /* Now wider on mobile */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .about-box {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

/* --- Headings --- */
.about-box h2 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  text-align: center;
}
.about-box h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Ensures the indicator does not overlap */
  align-items: center;
  user-select: none;
}

/* --- Section Title --- */
.section-title {
  flex: 1;
  text-align: left;
}

/* --- Indicator Position (Now outside text) --- */
.accordion-indicator {
  font-size: 1.5rem;
  min-width: 25px; /* Ensures space for the '+' sign */
  text-align: right;
}

/* --- Section Content --- */
.section-content {
  overflow: hidden;
}
.section-content p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 12px;
}

/* --- Accordion Transition (Smooth Opening & Closing) --- */
.accordion-enter-active, .accordion-leave-active {
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.accordion-enter, .accordion-leave-to {
  max-height: 0;
  opacity: 0;
}
.accordion-enter-to, .accordion-leave {
  max-height: 500px;
  opacity: 1;
}

/* --- Section Spacing --- */
.about-box section {
  margin-bottom: 20px;
}

/* --- Mobile Tweaks --- */
@media (max-width: 767px) {
  .section-content {
    max-height: 250px;
    overflow-y: auto;
  }

  /* Increase box width */
  .about-box {
    width: 95%;
    padding: 20px;
  }

  /* Reduce heading font size for better fit */
  .about-box h3 {
    font-size: 1.3rem;
    padding-right: 30px; /* Avoids overlap with '+' sign */
  }

  /* Make indicator larger and aligned properly */
  .accordion-indicator {
    font-size: 1.6rem;
    min-width: 30px;
  }
}
</style>
<template>
    <div class="projects-view" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
      <h2>My Projects</h2>
      <ul>
        <li>Project 1: [Brief Description]</li>
        <li>Project 2: [Brief Description]</li>
        <li>Project 3: [Brief Description]</li>
      </ul>
    </div>
</template>

<script>
export default {
  name: 'ProjectsView',
  methods: {
    handleTouchStart(event) {
      this.startY = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      const touchY = event.touches[0].clientY;
      const deltaY = touchY - this.startY;

      const scrollTop = this.$el.scrollTop;
      const scrollHeight = this.$el.scrollHeight;
      const offsetHeight = this.$el.offsetHeight;

      // Prevent overscrolling
      if ((scrollTop === 0 && deltaY > 0) || (scrollTop + offsetHeight >= scrollHeight && deltaY < 0)) {
        event.preventDefault();
      }
    }
  }
}
</script>

<style scoped>
.projects-view {
  height: 100vh; /* Ensure it takes full height */
  overflow-y: auto; /* Allow vertical scrolling */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center content vertically */
}

body {
    padding: 0; 
    margin: 0; 
    background-color: black; 
    color: white; 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Use Apple font stack */
}
</style>